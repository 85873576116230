<template>

  <div>

    <div v-if="heroPanel" class="px-4 py-5 my-5 text-center shadowx">
      <!--img class="d-block mx-auto mb-4" src="@/assets/logo.png" alt="" width"72" height="57" /-->
      <h1 class="display-5 fw-bold col-6 offset-3" v-html="heroPanel.title"></h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4 py-3" v-html="heroPanel.details"></p>
        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <button
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3"
            @click="routerRedirect('company')"
          >Meet AVIAMS</button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-lg px-4"
            @click="routerRedirect('products')"
          >Safety Oversight Tools</button>
        </div>
      </div>
    </div>

    <div v-if="subHeroPanel" class="container col-xxl-8 px-4x py-5x">

      <div class="row flex-lg-row-reverse align-items-center g-5 py-5">

        <div class="col-10 col-sm-8 col-lg-6">
          <img
            :src="subHeroPanel.image"
            :alt="subHeroPanel.title"
            class="d-block mx-lg-auto img-fluid"
            width="700" height="500" loading="lazy"
          />
        </div>

        <div class="col-lg-6">
          <h1 class="display-5 fw-boldx lh-1x mb-4" v-html="subHeroPanel.title"></h1>
          <p class="lead" v-html="subHeroPanel.details"></p>

          <div class="display-6x mb-4 lead mt-5">
            <router-link
              v-if="false"
              :to="subHeroPanel.link">Learn more</router-link>
          </div>

          <!--div class="d-grid gap-2 d-md-flex justify-content-md-start hidden" hidden>
            <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
            <button type="button" class="btn btn-outline-secondary btn-lg px-4">Default</button>
          </div-->

        </div>

      </div>

      <div class="row g-5 py-5">

        <div class="col-lg-6" v-for="item in sections" v-bind:key="item['.key']">
          <h1 class="display-6 fw-boldx lh-1x mb-3" v-html="item.title"></h1>

            <router-link
              :to="item.link"
              v-if="item.link.indexOf('https:') < 0"
            >
              <div v-if="item.imageBg" class="shadow mb-4 box" style="width:100%; height:200px; margin-bottom:20px; max-height:150% !important;"
                :style="'background:url(' + item.image + ') bottom center no-repeat'"
              />
              <img v-if="!item.imageBg" class="shadow mb-4 box" :src="item.image" width="100%" />
            </router-link>
            <a
              :href="item.link"
              v-if="item.link.indexOf('https:') >= 0"
              target="_blank"
            >
              <div v-if="item.imageBg" class="shadow mb-4 box" style="width:100%; height:200px; margin-bottom:20px; max-height:150% !important;"
                :style="'background:url(' + item.image + ') bottom center no-repeat'"
              />
              <img v-if="!item.imageBg" class="shadow mb-4 box" :src="item.image" width="100%" />
            </a>

          <p class="lead" v-html="item.details"></p>
          <div class="display-6x mb-4 lead mt-3">
            <router-link
              v-if="item.link.indexOf('https:') < 0 && false"
              v-bind:to="'' + item.link"
            >Learn more</router-link>
            <a
              v-if="item.link.indexOf('https:') >= 0"
              :href="item.link"
              target="_blank"
            >Learn more</a>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          </div>
        </div>

        <div v-if="false" class="col-lg-6">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            International Standards
          </h1>

          <div class="shadow mb-4 box" style="width:100%; height:200px; margin-bottom:20px; background:url(https://live.staticflickr.com/3851/15334518496_d57e263ef9_b.jpg) center center no-repeat" />

          <p class="lead">Commited to building global solutions for safety oversight organisations</p>
          <div class="display-6x mb-4 lead mt-3">
            <a href="https://adamsws.com">Learn more</a>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          </div>
        </div>

        <div v-if="false" class="col-10 col-sm-8 col-lg-6">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            Training
          </h1>
          <div class="shadow mb-4 box" style="width:100%; height:200px; margin-bottom:20px; background:url(https://xyzaviation.africa/wp-content/uploads/2019/09/new-home-image.jpg) center center no-repeat" />
          <p class="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit.</p>
          <div class="display-6x mb-4 lead mt-3">
            <a href="https://adamsws.com">Learn more</a>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          </div>
        </div>

        <div v-if="false" class="col-10 col-sm-8 col-lg-6">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            Audit Preparations
          </h1>
          <div class="shadow mb-4 box" style="width:100%; height:200px; margin-bottom:20px; background:url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.EYUPwHXU9lvDe6H9hZoX1AHaEH%26pid%3DApi&f=1) bottom center no-repeat" />
          <p class="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit.</p>
          <div class="display-6x mb-4 lead mt-3">
            <a href="https://adamsws.com">Learn more</a>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          </div>
        </div>

        <div v-if="false" class="col-10 col-sm-8 col-lg-6">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            Software Solutions
          </h1>
          <!--div style="width:100%; height:200px; margin-bottom:20px; background:url(https://adamsws.com/static/adams-work-risk-analysis-38746723874hfe34h845gg32ouha8v3ju4.png) center center no-repeat" /-->
          <img class="shadow mb-4 box" src="https://adamsws.com/static/adams-work-risk-analysis-38746723874hfe34h845gg32ouha8v3ju4.png" width="100%" />
          <p class="lead">Featuring Sass variables and mixins, responsive grid system, extensive prebuilt components.</p>
          <div class="display-6x mb-4 lead mt-3">
            <a href="https://adamsws.com">Learn more</a>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          </div>
        </div>

      </div>

    </div>

    <div
      v-if="false"
      class="row g-5 py-5"
    >
      <div class="col-12" style="text-align:center;">
        <h1 class="display-6 fw-boldx lh-1x mb-3">
          Team
        </h1>
      </div>
      <div class="mt-5 mb-5 offset-3 col-6">
        <div class="row">
          <a href="#" class="col-6 offset-3 no-border mb-3">
            <div class="card p20">
              <div class="row">
                <div class="col-3">
                  <img
                    src="https://scontent-sjc3-1.xx.fbcdn.net/v/t1.18169-9/401139_405466239567492_724402863_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=O6H9Tffue0gAX-n5ypL&_nc_ht=scontent-sjc3-1.xx&oh=21aedb0d16ca119d0902891dd0430b71&oe=615D756C"
                    style="width:80px; border-radius:50%;"
                  />
                </div>
                <div class="col px-4">
                  <div class="lead fw-boldx">
                    Emmanuel Akatue
                  </div>
                  CEO
                </div>
              </div>
            </div>
          </a>
          <a href="#" class="col-6 no-border mb-3">
            <div class="card p20">
              <div class="row">
                <div class="col-3">
                  <img
                    src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.si1-C2XDnPjtc_5Y5nmRtgHaHa%26pid%3DApi&f=1"
                    style="width:80px; border-radius:50%;"
                  />
                </div>
                <div class="col px-4">
                  <div class="lead fw-boldx">
                    Ernest Akatue
                  </div>
                  CFO
                </div>
              </div>
            </div>
          </a>
          <a href="#" class="col-6 no-border mb-3">
            <div class="card p20">
              <div class="row">
                <div class="col-3">
                  <img
                    src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.si1-C2XDnPjtc_5Y5nmRtgHaHa%26pid%3DApi&f=1"
                    style="width:80px; border-radius:50%;"
                  />
                </div>
                <div class="col px-4">
                  <div class="lead fw-boldx">
                    Ugochukuw Nwigwe
                  </div>
                  CTO
                </div>
              </div>
            </div>
          </a>
          <a href="#" class="col-4 no-border">
          </a>
        </div>
      </div>
    </div>

    <div class="row g-5 py-5">

      <div class="offset-2 col-8">
        <div class="row">
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          >
            <div class="row mb-5">
              <div
                class="col-12"
                style="text-align:center;">
                <h1 class="display-6 fw-boldx lh-1x mb-3">
                  Services
                </h1>
                <div class="mt-5 mb-5">
                  <p class="lead">
                    AVIAMS Consult provides its clients, a wide range of consultancy services ranging from safety strategic planning to safety inspector training tailored to keeping the CAA up to standard and efficient as regards to safety oversight.
                  </p>

                  <div class="display-6x mb-4 lead mt-5">
                    <router-link to="/services">Learn more</router-link>
                  </div>
                </div>
              </div>
              <div v-if="false" class="col-12">
                <div class="row">
                  <div v-for="item in services" v-bind:key="item['.key']" class="col-4 mt-1 mb-2">
                    <a href="#" v-html="item.title"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
          >
            <div class="row mb-5">
              <div class="col-12" style="text-align:center;">
                <h1 class="display-6 fw-boldx lh-1x mb-3">
                  Products
                </h1>
                <div class="mt-5 mb-5 col-12">
                  <p class="lead">
                    In a bid to help our clients automate their civil aviation processes in compliance with international standards, we provide a range of safety oversight tools, designed to record, evaluate and analyse Aviation Data in Real Time.
                  </p>

                  <div class="display-6x mb-4 lead mt-5">
                    <router-link to="/products">Learn more</router-link>
                  </div>
                </div>
              </div>
              <div v-if="false" class="col-12">
                <div class="row">
                  <div v-for="item in products" v-bind:key="item['.key']" class="col-4 mt-1 mb-2">
                    <a href="#" v-html="item.title"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="row g-5 py-5">
      <div class="row mb-5">
        <div class="col-12" style="text-align:center;">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            Clients
          </h1>
          <div class="mt-5 mb-5 offset-2 col-8">
            <a
              href="https://www.icao.int"
              target="_new"
              class="m-4 no-border"
            >
              <img
                src="@/assets/clients/aviams-client-icao.png"
                width="80px"
              />
            </a>
            <a href="www.gcaa.com.gh"
              target="_new"
              class="m-4 no-border"
            >
              <img
                src="@/assets/clients/aviams-client-gcaa-gh.png"
                width="170px"
                class="m-4"
              />
            </a>
            <a href="https://www.gacl.com.gh/"
              target="_new"
              class="m-4 no-border"
            >
              <img
                src="@/assets/clients/aviams-client-airport-gh.png"
                width="170px"
                class="m-4"
                />
            </a>
            <a href="https://aibghana.gov.gh/"
              target="_new"
              class="m-4 no-border"
            >
              <img
                src="@/assets/clients/aviams-client-aib-gh.png"
                width="170px"
                class="m-4"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-5 py-5">
      <div class="row mb-5 offset-3">
        <div class="col-3">
          <h1 class="display-6 fw-boldx lh-1x mb-3">
            Contact Us
          </h1>
          <p>
            We would love to hear from you.
          </p>
          <p><br /></p>
          <p
            class="mt-4"
            style="text-align:center;"
          >
            <router-link
              :to="'/contact'"
              type="button"
              class="btn btn-secondary btn-lg px-4 white"
            >Send us a request</router-link>
          </p>
        </div>
        <div class="col-6">
          <p>
            AVIAMS Consult Limited<br /><br />
            15 Kormeiteh Street,<br />
            Community 18, Spintex Road<br />
            Accra, GHANA<br /><br />
            <a href="mailto:admin@aviamsconsult.com">admin@aviamsconsult.com</a><br />
          </p>
        </div>
      </div>
    </div>


    <div class="shadow-lg"></div>

    <div class="row" style="background:#F0F0F2;">
      <div class="col-12 h0 shadow">&nbsp;</div>
      <div class="col-8 offset-2 mt-5 mb-5">
        <div class="row">
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          >
            <div class="display-6 mb-4 mt-5">
              <a href="https://adamsws.com" target="_blank">
                ADAMS, all your safety oversight data needs in one place
              </a>
            </div>
            <p class="lead mt-5">
              Incorporate analytical graphs and tools for recorded office and regional safety oversight data: Work Activities, Training Data, Foreign Aircraft Suriveillance Information, PEL, Aircraft, Accident Data and Risk Analysis
            </p>

            <div class="display-6x mb-4 lead mt-5">
              <a href="https://adamsws.com" target="_blank">Learn more</a>
            </div>
          </div>
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          >
            <a href="https://adamsws.com">
              <div class="shadow" style="width:100%; height:400px; margin-bottom:20px; background:url(https://adamsws.com/static/adams_work_data-0251b6e09313acf26a1757a61b9115fc.png) top left no-repeat; background-size:cover;" />
              <!--img src="https://adamsws.com/static/adams_work_data-0251b6e09313acf26a1757a61b9115fc.png" style="width:100%;" /-->
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  methods: {
    routerRedirect (path) {
      if ([null, undefined].includes(path)) { return }
      this.$router.push(path)
    },
    urlRedirect (url) {
      if ([null, undefined].includes(url)) { return }
      location.href = url
    }
  },
  data () {
    return {
      heroPanel: {
        title: 'Safety Oversight <br>Consultancy by AVIAMS',
        image: 'https://www.pwc.com/gx/en/brand-simplified/hero-images-1600/financial-services/damil-gettyimages-975027882-1600.jpg',
        imageBg: true,
        details: 'Commited to building global solutions for safety oversight organisations.',
        link: 'https://adamsws.com'
      },
      subHeroPanel: {
        title: 'International Standards, <br>the Client\'s Way',
        image: 'http://cxofbo.com/GeneralAviationServices/wp-content/uploads/2018/01/Jet-engine-2000x1000.jpeg',
        imageBg: true,
        details: 'Our products and services to our clients, ensure a close adherence to the stipulations of international standards for civil aviation safety oversight (ICAO, EASA, FAA). However, we ensure that the perculiar needs of the clients are completely met.',
        link: '/standards'
      },
      sections: [
        // https://live.staticflickr.com/3851/15334518496_d57e263ef9_b.jpg
        // https://i2.wp.com/s28477.pcdn.co/wp-content/uploads/2018/01/JFK_1-984x554.jpg?ssl=1
        // https://xyzaviation.africa/wp-content/uploads/2019/09/new-home-image.jpg
        {
          title: 'Technical Consultancy',
          image: 'https://www.pwc.com/gx/en/brand-simplified/hero-images-1600/financial-services/damil-gettyimages-975027882-1600.jpg',
          imageBg: true,
          details: 'AVIAMS Consult provides its clients, a wide range of consultancy services ranging from safety strategic planning to safety inspector training tailored to keeping the CAA up to standard and efficient as regards to safety oversight.',
          link: '/consultancy'
        },
        {
          title: 'Personnel Training',
          image: 'https://i2.wp.com/s28477.pcdn.co/wp-content/uploads/2018/01/JFK_1-984x554.jpg?ssl=1',
          imageBg: true,
          details: 'Our instructors are ICAO Certified to provide training for Government Safety Inspectors in Air Operator Certification (AOC), Approved Maintenance Organisation (AMO) Certification and Personnel Licensing.',
          // AVIAMS Consult also provides customized training in various Civil Aviation Courses including Resolution of Safety and Security Concerns for Civil Aviation Personnel. <router-link to="/audit-prep">See full list of training packages provided.</a>
          link: '/training'
        },
        {
          title: 'Audit Preparations',
          image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.EYUPwHXU9lvDe6H9hZoX1AHaEH%26pid%3DApi&f=1',
          imageBg: true,
          details: 'In preparation for international and regional civil aviation audits, we assist our clients through technical consultancy programmes and specialized training packages.',
          // AVIAMS Consult provided one year Technical Consultancy Services to GCAA in preparation and through its last ICAO ICVM Audit which saw Ghana emerge with the highest ICAO Effective Implementation (EI) score in Africa of 89.98% in March 2019.
          link: '/audit-prep'
        },
        {
          title: 'Software Solutions',
          image: 'https://adamsws.com/static/adams-work-risk-analysis-38746723874hfe34h845gg32ouha8v3ju4.png',
          imageBg: false,
          details: 'In a bid to help our clients automate their civil aviation processes in compliance with international standards, we provide a range of safety oversight tools, designed to record, evaluate and analyse Aviation Data in Real Time.',
          link: 'https://adamsws.com'
        }
      ],
      services: [
        {
          title: 'SSP development and implementation',
          path: ''
        },
        {
          title: 'Safety Management System (SMS) implementation',
          path: ''
        },
        {
          title: 'SSP and SMS training',
          path: ''
        },
        {
          title: 'Guidance on technical procedures to promote and improve safety management oversight',
          path: ''
        },
        {
          title: 'Implementation assistance in ICAO or EASA',
          path: ''
        },
        {
          title: 'Safety management regulatory tools',
          path: ''
        },
        {
          title: 'PBR analysis and transformation plan development',
          path: ''
        },
        {
          title: 'Implementation advice and support',
          path: ''
        },
        {
          title: 'Creation of specific Safety Performance Indicators (SPIs)',
          path: ''
        },
        {
          title: 'Technological solutions to support PBR',
          path: ''
        },
        {
          title: 'Theoretical and practical training to NAA staff at all levels',
          path: ''
        },
        {
          title: 'Support in establishing a cooperative relationship with industry',
          path: ''
        },
        {
          title: 'Strategy and business plan definition',
          path: ''
        },
        {
          title: 'Safety and other regulatory objectives definition',
          path: ''
        },
        {
          title: 'Review and recommendations on NAA organisation structure',
          path: ''
        }
      ],
      products: [
        {
          title: 'Assistance in separation of regulatory oversight and service provision',
          path: ''
        },
        {
          title: 'Job descriptions and competencies',
          path: ''
        },
        {
          title: 'Training Needs Analysis (TNA), training plan development and delivery',
          path: ''
        }
      ]
    }
  }
}
</script>
