<template>

  <div style="position:relative;">

    <div
      class="row shadow"
      style="position:fixed; left:0px; top:0px; width:110% !important; background:#ffffff; z-index:1;"
    >

      <!--div class="dauto mt-2x ml-3x mr-3x"></div-->

      <nav class="col navbar navbar-expand-lg navbar-light bg-lightx">
        <router-link
          to="/"
          class="navbar-brand nav-link active logo-link"
        >
          <div class="row">
            <div class="col-3">
              <img
                alt="AVIAMS Consult"
                src="../assets/logo.png"
                class="logo"
              />
            </div>
            <div class="col">
              <div class="fw-bold f15">AVIAMS</div>
              <div class="f10">Consult Limited</div>
            </div>
          </div>
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="nav navbar-nav mr-auto">
            <li
              class="nav-item"
              v-for="item in menu"
              v-bind:key="item['.key']"
            >
              <router-link
                class="nav-link active mt-1x mb-1"
                v-if="item.title.length > 0 || (item.show && item.show === false)"
                :class="item.class"
                :to="'/' + item.path"
                v-html="item.title"
              ></router-link>
            </li>
          </ul>
        </div>
      </nav>

      <!--div class="col">
        <ul class="nav justify-content-end">
          <li
                v-if="item.left"
            class="nav-item"
            v-for="item in menu"
            v-bind:key="item['.key']"
          >
            <router-link
              v-if="item.right"
              class="nav-link active mt-1"
              :class="item.class"
              :to="'/' + item.path"
              v-html="item.title"
            ></router-link>
          </li>
        </ul>
      </div-->
    </div>

  </div>

</template>
<script>
export default {
  name: 'Navigation',
  data () {
    return {
      menu: [
        {
          title: 'Home',
          path: '',
          class: '',
          show: true,
          left: true
        },
        {
          title: 'Company',
          path: 'company',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: true,
          left: true
        },
        {
          title: 'Services',
          path: 'services',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: true,
          left: true
        },
        {
          title: 'Products',
          path: 'products',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: true,
          left: true
        },
        /*
        {
          title: 'Clients',
          path: 'clients',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: true,
          left: true
        },
        {
          title: '',
          path: '',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: true,
          left: true
        },
        */
        {
          title: 'Contact',
          path: 'contact',
          class: 'col-0 col-xs-0 col-sm-0 col-md-0',
          show: false,
          right: true
        }
      ]
    }
  }
}
</script>
<style scoped>
</style>
