<template>
  <div class="footer">
    <div style="background:#232939; width:100vw; color:white; padding: 30px 0px;">
      <div class="col-8 offset-2">
        <div class="row">
          <router-link to="/" class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-5">
            <div class="dauto">
              <img src="@/assets/logo-dark.png" width="50px" />
              <br /><br />
            </div>
            <div class="fw-boldx">
              <div class="white"><div class="fw-bold f20">AVIAMS</div>Consult Limited</div>
              <div class="white">Copyright 2021</div>
            </div>
          </router-link>
          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div
                class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mb-2"
              >Sitemap</div>
              <router-link
                :to="'/' + item.path"
                class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mb-2"
                v-for="item in nav" v-bind:key="item['.key']"
              >
                <span class="footer-link"
                  v-html="item.title"
                >
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      nav: [
        {
          title: 'Home',
          path: ''
        },
        {
          title: 'Company',
          path: 'company'
        },
        {
          title: 'Services',
          path: 'services'
        },
        {
          title: 'Products',
          path: 'products'
        },
        {
          title: 'Contact',
          path: 'contact'
        }
      ]
    }
  }
}
</script>
