import Vue from "vue";
import Vuex from "vuex";
import * as fb from '../firebase'
import store from '@/store/index'
// import router from '../router/index'

Vue.use(Vuex);

// prepare collections
fb.aviamsRequestCollection
    .orderBy('first_name', 'asc')
    .onSnapshot(snapshot => {
        let postsArray = []
        snapshot.forEach(doc => {
            let post = doc.data()
            post.id = doc.id
            postsArray.push(post)
        })
        store.commit('setAviamsRequestList', postsArray)
    })
  

export default new Vuex.Store({
  state: {
    user: {
      isLoggedIn: false,
      data: null
    },
    users: [],
    aviamsRequestList: [],
    aviamsRequest: {},
    aviamsRequestSet: false,
    products: []
  },
  getters: {
    user(state){
      return state.user
    },
    users(state) {
        return state.users
    },
    aviamsRequestList(state) {
        return state.aviamsRequestList
    },
    aviamsRequestSet(state) {
        return state.aviamsRequestSet
    },
    aviamsRequest(state) {
        return state.aviamsRequest
    },
    products(state) {
        return state.products
    }
  },
  mutations: {
    setUsers(state, val) {
        state.users = val
    },
    setAviamsRequestList(state, val) {
      state.aviamsRequestList = val
    },
    setAviamsRequestSet(state, val) {
      state.aviamsRequestSet = val
    },
    setAviamsRequest(state, val) {
      state.aviamsRequest = val
    },
    setProducts(state, val) {
      state.products = val
    },
    SET_LOGGED_IN(state, value) {
      state.user.isLoggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    resetAviamsRequestSet ({ state, commit }, value) {
      console.log(typeof commit, typeof state, typeof value)
      store.commit('setAviamsRequestSet', value)
    },

    async createAviamsRequest({ state, commit }, post) {
      console.log(typeof commit, typeof state)
      await fb.aviamsRequestCollection.add({
        timestamp: new Date(),
        ...post
        // userId: fb.auth.currentUser.uid,
        // userName: state.userProfile.name,
      })
      .then(function () {
        // this.$emit('aviamsRequestAdded', true)
        store.commit('setAviamsRequestSet', true)
      })
      .catch(function(e) {
        console.log(e)
        // this.$emit('aviamsRequestAdded', false)
        store.commit('setAviamsRequestSet', false)
      })
    },

    async updateAviamsRequest({ state, commit }, post) {
      console.log(typeof commit, typeof state)
      if ([null, undefined].includes(post.keyId)) { return false }
      // update the time stamp
      post.timestamp = new Date()
      await fb.aviamsRequestCollection
        .doc(post.keyId)
        .update({ ...post })
          .then(function () {
            store.commit('setAviamsRequestSet', true)
            store.commit('setAviamsRequest', {})
          })
          .catch(function(e) {
            console.log(e)
            store.commit('setAviamsRequestSet', false)
          })
    },

    recoverAviamsRequest({ state, commit }, data) {
      console.log(typeof state, commit)
      fb.aviamsRequestCollection
        .where("email", "==", data.email)
        .onSnapshot(snapshot => {
            if (snapshot) {
              let dataItem = snapshot.docs[0].data()
              dataItem.keyId = snapshot.docs[0].id
              console.log(dataItem)
              store.commit('setAviamsRequest', dataItem)
            } else {
              console.log('RSVP not found')
            }
        })
    },
    
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
            displayName: user.displayName,
            email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    }
  }
});
