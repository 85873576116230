<template>

  <div id="app">

    <Navigation />

    <router-view />

    <Footer />

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import firebase from 'firebase/app'
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Navigation, Footer
  },
  metaInfo: {
    title: 'AVIAMS Consult Limited',
    titleTemplate: '%s ← AVIAMS Consults',
    link: [
      {rel: 'canonical', href: 'https://www.aviamsconsult.com'}
    ],
    meta: [
      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'AVIAMS Consult is a specialized aviation safety oversight consultancy agency based in Africa. We are commited to building global, enterprise-level solutions for safety oversight challenges facing the region.'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'Home ← AVIAMS Consults'},
      {property: 'og:site_name', content: 'AVIAMS Consults'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.aviamsconsult.com'},
      {property: 'og:image', content: 'https://www.aviamsconsult.com/img/logo.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'Commited to building global solutions for safety oversight organisations.'},
      // Twitter card
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: 'https://www.aviamsconsult.com'},
      {name: 'twitter:title', content: 'Home ← AVIAMS Consults'},
      {name: 'twitter:description', content: 'Commited to building global solutions for safety oversight organisations.'},
      // Your twitter handle, if you have one.
      {name: 'twitter:creator', content: '@aviamsc'},
      {name: 'twitter:image:src', content: 'https://www.aviamsconsult.com/img/logo.png'},
      // Google / Schema.org markup:
      {itemprop: 'name', content: 'Home ← AVIAMS Consults'},
      {itemprop: 'description', content: 'Commited to building global solutions for safety oversight organisations.'},
      {itemprop: 'image', content: 'https://www.aviamsconsult.com/img/logo.png'}
    ]
  },
  computed: {
    ...mapGetters({
      user: 'user',
      aviamsReqList: 'aviamsReqList',
      aviamsReq: 'aviamsReq',
      aviamsReqSet: 'aviamsReqSet'
    }),
  },
  mounted () {
    window.scrollTo(0, 0)
    // this.scrollToTop()
  },
  methods: {
    urlRedirect (url) {
      if ([null, undefined].includes(url)) { return }
      location.href = url
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    setPlace(value, places) {
      value = parseInt(value)
      let result = value
      for (let i=1; i<places; i++) {
        if (value < (10 ^ places[i])) {
          result = '0' + result
        }
      }
      return result
    },

    showRSVPCard() {
      this.show.alert = true
    },

    showEditRSVP(code, e) {
      e.preventDefault()
      if (code) {
        if (code.length > 0) {
          this.show.aviamsReq = code
        }
      }
    },

    resetRsvp(code) {
      this.$store.dispatch('resetRsvpSet', false)
      this.guest = this.config.nullRequest
      this.aviamsReq = null
      this.show.aviamsReq = code
    },

    validateRsvp() {
      let data = (this.show.aviamsReq === 'edit') ? this.aviamsReq : this.guest

      if (
        ([null, undefined].includes(data))
        || (Object.keys(data) <= 0)
        || !(
          ('name' in data)
          && ('email' in data)
          && ('phone' in data)
          && ('address' in data)
          && ('state' in data)
          && ('count' in data)
        )
      ) { return false }

      if (
        data.name.length > 0
        && data.email.length > 0
        && data.phone.length > 0
        && data.address.length > 0
        && data.state.length > 0
        && data.count.length > 0
      ) {
        return true
      } else {
        return false
      }
    },

    recoverRSVP (e) {
      e.preventDefault()
      this.$store.dispatch('recoverRsvp', this.guestSearch)
      this.show.aviamsReq = 'edit'
      // this.guest = { name: '', email: '', pin: '', }
    },

    updateRsvp(e) {
      e.preventDefault()
      this.$store.dispatch('updateRsvp', this.aviamsReq)
      this.show.aviamsReq = 'new'
    },

    createRsvp(e) {
      e.preventDefault()
      this.$store.dispatch('createRsvp', this.guest)
      // this.$emit('aviamsReqAdded', true)
      this.show.alert = true
      // console.log(this.guest)
      this.guest = this.config.nullRequest
    },

    submit() {

      if (this.password !== this.confirm_password) {
        this.error = {
          message: 'Password does not match',
          status: 'warning',
        }
        return
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          data.user
            .updateProfile({
              // set the username
              displayName: this.form.name
            })
            .then(() => {
              // clear the form
              this.form = { name: '', password: '', confirm_password: '', email: '' }
              // set the display
              this.error = {
                message: 'An account has been created for you. Thank you.',
                status: 'success',
              }
            });
        })
        .catch(err => {
          this.error = {
            message: err.message,
            status: 'danger'
          }
        });
    },

    /*
    createRsvp(name, date) {
      db.collection("aviamsReq")
        .add({ date: date, name: name })
        .then(() => {
          this.status = "Thank you, your RSVP form has been submitted"
          console.log(this.status);
        })
        .catch((error) => {
          this.status = "There was an error. Please try submitting the form again"
          console.error(this.status, error);
        });
    },
    readRSVP() {
      let employeesData = [];
      db.collection('aviamsReq')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
           employeesData.push({
              id: doc.id,
              name: doc.data().name,
              date: doc.data().date,
            });
            console.log(doc.id, " => ", doc.data());
          });
          return employeesData
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    */

  },
  data () {
    return {
      show: {
      },
      request: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        country: '',
        role: '',
        org: '',
        org_size: '',
        timestamp: '',
      },
      config: {
        nullRequest: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          address: '',
          country: '',
          role: '',
          org: '',
          org_size: '',
          timestamp: '',
        },
      },
      services: [
        {
          title: 'SSP development and implementation',
          path: ''
        },
        {
          title: 'Safety Management System (SMS) implementation',
          path: ''
        },
        {
          title: 'SSP and SMS training',
          path: ''
        },
        {
          title: 'Guidance on technical procedures to promote and improve safety management oversight',
          path: ''
        },
        {
          title: 'Implementation assistance in ICAO or EASA',
          path: ''
        },
        {
          title: 'Safety management regulatory tools',
          path: ''
        },
        {
          title: 'PBR analysis and transformation plan development',
          path: ''
        },
        {
          title: 'Implementation advice and support',
          path: ''
        },
        {
          title: 'Creation of specific Safety Performance Indicators (SPIs)',
          path: ''
        },
        {
          title: 'Technological solutions to support PBR',
          path: ''
        },
        {
          title: 'Theoretical and practical training to NAA staff at all levels',
          path: ''
        },
        {
          title: 'Support in establishing a cooperative relationship with industry',
          path: ''
        },
        {
          title: 'Strategy and business plan definition',
          path: ''
        },
        {
          title: 'Safety and other regulatory objectives definition',
          path: ''
        },
        {
          title: 'Review and recommendations on NAA organisation structure',
          path: ''
        }
      ],
      products: [
        {
          title: 'Assistance in separation of regulatory oversight and service provision',
          path: ''
        },
        {
          title: 'Job descriptions and competencies',
          path: ''
        },
        {
          title: 'Training Needs Analysis (TNA), training plan development and delivery',
          path: ''
        }
      ]
    }
  }
}
</script>

<style>

body { overflow-x: hidden; overflow-y: visible !important; }

.logo-link {  border-bottom: 0px !important; }

a, a:hover, a:visited { color:#031B4E !important; text-decoration:none !important; border-bottom:dashed 1px #031B4E; }

ul.nav li a, ul.nav li a:hover, ul.nav li a:visited { color:#031B4E; font-weight:bold; border-bottom:none; }

.logo { margin:5px 10px; width:25px; height:25px; }

.h0 { height:3px !important; }

.p0 { padding:0px !important; }
.p5 { padding:5px !important; }
.p10 { padding:10px !important; }
.p20 { padding:20px !important; }

.m0 { margin:0px !important; }
.m10 { margin:10px !important; }

.white { color:white !important; }
a.white, a.white:hover, a.white:visited { color:white !important; }

.footer { padding-top: 50px; background:#F0F0F2 !important; }
.footer-link { color:white !important; }
.footer-link:hover { color:#F0F0F2 !important; }
.box { border-radius:10px; }
.no-border { border: none !important; }
.dauto { float:left !important; overflow:hidden !important; width:auto !important; }

.f8 { font-size:8px !important; }
.f9 { font-size:9px !important; }
.f10 { font-size:10px !important; }
.f15 { font-size:15px !important; }
.f20 { font-size:20px !important; }
.f25 { font-size:25px !important; }
.f30 { font-size:30px !important; }

.screen { margin-top: 80px !important; height:60vh !important; }

.col-0 { display: none !important; }
@media (max-width: 575px) { .col-xs-0 { display: none !important; } .col-0, .col-sm-0, .col-md-0, .col-lg-0, .col-xl-0 { display: block !important; } }
@media (min-width: 576px) { .col-sm-0 { display: none !important; } .col-0, .col-xs-0, .col-md-0, .col-lg-0, .col-xl-0 { display: block !important; } }
@media (min-width: 768px) { .col-md-0 { display: none !important; } .col-0, .col-xs-0, .col-sm-0, .col-lg-0, .col-xl-0 { display: block !important; } }
@media (min-width: 992px) { .col-lg-0 { display: none !important; } .col-0, .col-xs-0, .col-sm-0, .col-md-0, .col-xl-0 { display: block !important; } }
@media (min-width: 1200px) { .col-xl-0 { display: none !important; } .col-0, .col-xs-0, .col-sm-0, .col-md-0, .col-lg-0 { display: block !important; } }

/* #F0F0F2 #00d7d2 */
</style>
