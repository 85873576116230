// import * as firebase from 'firebase/app'
import firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyCgevGt-fU-NjP1xAjngWqdFH09AMgT1Lg",
  authDomain: "blink-12db9.firebaseapp.com",
  databaseURL: "https://blink-12db9-default-rtdb.firebaseio.com",
  projectId: "blink-12db9",
  storageBucket: "blink-12db9.appspot.com",
  messagingSenderId: "1083662169850",
  appId: "1:1083662169850:web:8c6ee4c10e17d2fe28fb43",
  measurementId: "G-1T9RGVNBMM"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const aviamsRequestCollection = db.collection('aviams.requests')
const adamsRequestCollection = db.collection('adams.requests')

// export utils/refs
export {
  db,
  auth,
  aviamsRequestCollection,
  adamsRequestCollection,
}
