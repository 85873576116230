import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue'

Vue.use(Router)

const routes = [
    {
      path: '*',
      name: '404',
      component: Home
    },
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/company',
      name: 'Company',
      component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue')
    },
    {
      path: '/clients',
      name: 'Clients',
      component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue')
    },
    {
      path: '/services',
      name: 'Services',
      component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue')
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    }
]
  
const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.replace({ path: '*', redirect: '/' })

export default router
